<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
        <el-table :data='spDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="user_name" label="姓名"></el-table-column>
            <el-table-column align="center" prop="user_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="user_login_id" label="用户名"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="spForm" :rules="rules" ref="spForm" label-width="100px" size='medium'>
                <el-form-item label="姓名" prop="user_name">
                    <el-input v-model="spForm.user_name"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="user_login_id" >
                    <el-input v-model="spForm.user_login_id" :disabled="spForm.user_id===0?false:true"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" >
                    <el-input v-model="spForm.user_phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      spDataList: [],
      title: '',
      dialogVisible: false,
      User:null,
      spForm: {
        user_id: 0,
        user_name: '',
        user_phone: '',
        user_login_id: ''
      },
      rules: {
        user_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        user_login_id: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.User=util.JesToken()
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Get('user/getuserdatalist?userType=3&id='+this.User.user_sp_id).then(res=>{
          if (res.rpStatus === 10000) {
          this.spDataList = res.list
        } else {
          this.$message.error(res.rpMsg)
        }
      })
    },
    AddClick () {
      this.title = '新增'
      this.dialogVisible = true
      this.spForm.user_id = 0
      this.spForm.user_name = ''
      this.spForm.user_phone = ''
      this.spForm.user_login_id = ''
    },
    EditClick (row) {
      this.title = '修改'
      this.dialogVisible = true
      this.spForm = JSON.parse(JSON.stringify(row))
    },
    DelClick (row) {
      this.$confirm('确定删除销售员“' + row.user_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Get('user/del?id=' + row.user_id).then(res => {
          if (res.rpStatus === 10000) { this.GetDataList() } else { this.$message.error(res.rpMsg) }
        })
      }).catch(e => e)
    },
    submit () {
      this.$refs.spForm.validate((valid) => {
        if (valid) {
          this.spForm.user_sp_id=this.User.user_sp_id
          this.spForm.user_type=3
          util.Post('user/edit', this.spForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
